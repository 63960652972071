import { Component, ViewChild, ElementRef, Inject, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language.service';
import { SharedService } from '@services/shared.service';
import { CatalogueService, CatalogueModes } from '@services/catalogue/catalogue.service';
import { BrandCategory } from '@model/brand.category.model';
import { PageScrollService } from 'ngx-page-scroll-core';
import { UserService } from '@services/user/user.service';
import { CatalogueGridService } from '@services/catalogue/catalogue.grid.service';
import { ExportFunction, ExportObj, TableDataGetterParams } from '@app/model/table.model';
import { ForceFilter } from './filterComponent/filter.component';
import { Representative } from '@app/model/user.model';
import { PermissionsService } from '@app/services/permissions.service';

@Component({
    selector: 'app-catalogue',
    templateUrl: 'catalogue.component.html',
    styleUrls:  ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit, OnDestroy {
    public catalogueMode: CatalogueModes;
    public catalogueModes = CatalogueModes;
    productionGroup1: string;
    productionGroup2: string;
    page: number;
    orderBy: string;
    detailId: number;
    public brandCategories: BrandCategory[];
    breadCrumbs: BrandCategory[];
    totalRows: number;
    public subjectRefresh = new Subject<any>();
    // public exportArray: { name: string, fileName: string, export: Function, excludeCols: string[], customColNames: any }[];
    public exportArray: ExportObj[];
    public exportObj: ExportObj;
    public forceFilter$ = new Subject<ForceFilter>();
    public newProductsExist = false;
    public showProductSale = false;
    private subscriptions: Subscription[] = [];

    busy: boolean;

    // filters
    'eCommProductId.operator': string;
    'eCommProductId.value': string;
    'shortName.operator': string;
    'shortName.value': string;
    'dimensionB.operator': string;
    'dimensionB.value': string;
    'dimensionID.operator': string;
    'dimensionID.value': string;
    'dimensionOD.operator': string;
    'dimensionOD.value': string;
    'dateCreated.operator': string;
    'dateCreated.value': string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private languageService: LanguageService,
        public sharedService: SharedService,
        private catalogueService: CatalogueService,
        private gridService: CatalogueGridService,
        private userService: UserService,
        private permissionsService: PermissionsService,
        private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any
    ) {
        this.subjectRefresh = new Subject<any>();

        this.getProducts = this.getProducts.bind(this);
        this.getGridOptions = this.getGridOptions.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getColumnsDefs = this.getColumnsDefs.bind(this);

        // to reload when menu item is clicked but it is already routed to this area
        this.sharedService.subjectSameAreaClicked.subscribe(res => {
            this.subjectRefresh.next();
        });

        this.subscriptions.push(this.languageService.getLanguageObservable().subscribe(() => {
            if (this.catalogueMode) { // only call when catalogueMode is set - after ngOnInit
                this.brandCategories = null;
                this.getBrandCategories();
            }
        }));
    }

    public ngOnInit(): void {
        // sk/purchase-catalogue or sk/purchase-catalogue/ZVL/1200
        if (this.route.snapshot.url.length === 2 || this.route.snapshot.url.length === 4) {
            this.catalogueMode = this.catalogueService.getCatalogueModeFromPath(this.route.snapshot.url[1].path);

            this.showProductSale = this.catalogueMode === CatalogueModes.Catalogue &&
                this.sharedService.hasPermission('products-reduced/5', 'GET') &&
                this.sharedService.user.representative === Representative.customer &&
                this.sharedService.params.features.catalogue.productSalePriceListCode !== undefined;
        }
        console.log('ngOnInit this.catalogueMode', this.catalogueMode);
        this.setExport();

        // No need to unsubscribe, the observable completes
        this.catalogueService.getNewProductsExist(this.catalogueMode).subscribe(exist => {
            this.newProductsExist = exist;
        });

        setTimeout(() => {
            // navigate with orderby parameter
            if (!this.route.snapshot.params.hasOwnProperty('id')) {
                this.sharedService.navigateWithOrderby(this.getColumns(),
                    this.route.snapshot.queryParams, this.sharedService.lastCatalogueQueryParams);
            }
        }, 0);

        this.route.queryParams.subscribe(params => {
            if (!this.detailId) {
                this.init();
            }
        });
    }

    private setExport(): void {
        // The basic export
        const exportFunction: ExportFunction = query => {
            return this.catalogueService.exportAll(query, this.catalogueMode);
        };
        
        // Export with extended availability columns
        const exportExtendedFunction: ExportFunction = query => {
            return this.catalogueService.exportAllExtended(query, this.catalogueMode);
        };

        // Export with extended availability columns by months
        const exportExtendedByMonthFunction: ExportFunction = query => {
            return this.catalogueService.exportAllExtendedByMonth(query, this.catalogueMode);
        };

        this.sharedService.translateService.get('CATALOGUE').subscribe((catalogueTranslated: string) => {
            if (!this.permissionsService.hasPermission('ProductsExport')) {
                return;
            }
            const exportNormal: ExportObj = {
                name: 'EXPORT_TO_XLSX',
                fileName: catalogueTranslated,
                export: exportFunction.bind(this),
                excludeCols: ['amountOrder', 'priceTotal', 'add', 'available', 'availability', 'amountRfq', 'addRfq'],
                customColNames: {
                    weight: this.sharedService.translateService.instant('WEIGHT') + ' kg',
                    unitPrice: this.sharedService.translateService.instant('UNIT_PRICE'),
                    customerStockAmount: this.sharedService.capitalizeFirstLetter(this.sharedService.translateService.instant('CUSTOMER_STOCK')),
                    amount: this.sharedService.capitalizeFirstLetter(this.sharedService.translateService.instant('STOCK')),
                    amountUponVerification: this.sharedService.capitalizeFirstLetter(this.sharedService.translateService.instant('AMOUNT_UPON_VERIFICATION')),
                    futureDeliveryPeriod1_4: this.sharedService.capitalizeFirstLetter(this.sharedService.translateService.instant('IN_PRODUCTION')),
                    futureDeliveryPeriod5_7: this.sharedService.capitalizeFirstLetter(this.sharedService.translateService.instant('IN_PRODUCTION_PLAN')),
                    dimensionID: this.sharedService.translateService.instant('DIMENSIONS') + ' - d',
                    dimensionOD: this.sharedService.translateService.instant('DIMENSIONS') + ' - D',
                    dimensionB: this.sharedService.translateService.instant('DIMENSIONS') + ' - B'
                }
            };
            
            if (this.sharedService.params.features.catalogue.extendedExport && this.catalogueMode === CatalogueModes.Catalogue) {
                this.exportArray = [
                    exportNormal,
                    {
                        ...exportNormal, 
                        name: 'EXPORT_TO_XLSX_EXTENDED_AVAILABILITY', 
                        export: exportExtendedFunction.bind(this)
                    },
                    {
                        ...exportNormal, 
                        name: 'EXPORT_TO_XLSX_EXTENDED_AVAILABILITY_BY_MONTH', 
                        export: exportExtendedByMonthFunction.bind(this)
                    }
                ];
            } else {
                this.exportObj = exportNormal;
            }
        });
    }

    private init(): void {
        if (!this.route.snapshot.params.hasOwnProperty('id')) {
            if (!this.route.snapshot.queryParams.hasOwnProperty('group1') && !this.route.snapshot.queryParams.hasOwnProperty('group2') &&
                !this.route.snapshot.queryParams.hasOwnProperty('orderBy') && !this.route.snapshot.params.hasOwnProperty('id')
            ) {
                this.router.navigate([], { queryParams: this.sharedService.lastCatalogueQueryParams, replaceUrl: true });
                return;
            }
            this.sharedService.navigateWithOrderby(this.getColumns(),
                this.route.snapshot.queryParams, this.sharedService.lastCatalogueQueryParams);
            this.productionGroup1 = null;
            this.productionGroup2 = null;
            this.orderBy = null;
            // this.detailId = null;
            // this.page = null;
            this['brandCode.operator'] = null;
            this['brandCode.value'] = null;
            this['eCommProductId.operator'] = null;
            this['eCommProductId.value'] = null;
            this['shortName.operator'] = null;
            this['shortName.value'] = null;
            this['dimensionB.operator'] = null;
            this['dimensionB.value'] = null;
            this['dimensionID.operator'] = null;
            this['dimensionID.value'] = null;
            this['dimensionOD.operator'] = null;
            this['dimensionOD.value'] = null;
            this['dateCreated.operator'] = null;
            this['dateCreated.value'] = null;

            if (this.route.snapshot.queryParams.hasOwnProperty('group1')) {
                this.productionGroup1 = this.route.snapshot.queryParams['group1'];
            }
            if (this.route.snapshot.queryParams.hasOwnProperty('group2')) {
                this.productionGroup2 = this.route.snapshot.queryParams['group2'];
            }
        }

        this.getBrandCategories();

        if (this.route.snapshot.params.hasOwnProperty('id')) {
            this.detailId = this.route.snapshot.params['id'];
            this.scrollToTop();
            return;
        } else {
            this.detailId = null
        }

        if (this.route.snapshot.queryParams.hasOwnProperty('page')) {
            this.page = parseInt(this.route.snapshot.queryParams['page'], 10);
        } else { this.page = 0; }

        const attrs = ['brandCode', 'eCommProductId', 'shortName', 'dimensionB', 'dimensionID', 'dimensionOD', 'dateCreated'];

        attrs.forEach(attr => {
            if (this.route.snapshot.queryParams.hasOwnProperty(`${attr}.operator`)) {
                this[`${attr}.operator`] = this.route.snapshot.queryParams[`${attr}.operator`];
            }
            if (this.route.snapshot.queryParams.hasOwnProperty(`${attr}.value`)) {
                this[`${attr}.value`] = this.route.snapshot.queryParams[`${attr}.value`];
            }    
        });

        this.breadCrumbs = [new BrandCategory('catalogue', this.translate.instant('CATALOGUE'),
            '/' + this.catalogueService.getRoute(this.catalogueMode), { group1: 'all' })];

        if (this.productionGroup1) { this.breadCrumbs.push(this.getCategory(this.productionGroup1)); }
        if (this.productionGroup2) { this.breadCrumbs.push(this.getSubCategory(this.productionGroup2)); }

        // tell table observer to refresh
        if (!this.detailId) {
            this.sharedService.lastCatalogueQueryParams = {};
            for (const key of Object.keys(this.route.snapshot.queryParams)) {
                this.sharedService.lastCatalogueQueryParams[key] = this.route.snapshot.queryParams[key];
            }
        }
    }

    public onFilter(): void {
        this.subjectRefresh.next(0);
    }

    setPrefAndFilter(crumb) {
        this.userService.setUserPreference(this.sharedService.getUserPreferenceKey('Filter'), crumb.query, true);
        this.onFilter();
    }

    private getBrandCategories(): void {
        if (this.brandCategories) { return; }
        this.brandCategories = [];

        this.catalogueService.getBrandCategories(this.catalogueMode)
            .subscribe(data => {
                this.brandCategories = data;

                this.breadCrumbs = [new BrandCategory('catalogue', this.translate.instant('CATALOGUE'),
                    '/' + this.catalogueService.getRoute(this.catalogueMode), { group1: 'all' })];
            
                if (this.route.snapshot.queryParams.hasOwnProperty('group1')) {
                    this.breadCrumbs.push(this.getCategory(this.productionGroup1));
                    // open category that is in url and also subcategory is
                    if (this.route.snapshot.queryParams.hasOwnProperty('group2')) {
                        for (let i = 0; i < this.brandCategories.length; ++i) {
                            if (this.productionGroup1 === this.brandCategories[i].id) {
                                this.brandCategories[i].collapsed = false;
                            }
                        }
                    }
                }
                if (this.route.snapshot.queryParams.hasOwnProperty('group2')) {
                    this.breadCrumbs.push(this.getSubCategory(this.productionGroup2));
                }
            }, err => console.log(err));
    }

    public newProductsClicked(): void {
        this.forceFilter$.next({ showNewProducts: true });
    }

    public getProducts(params: TableDataGetterParams): void {
        this.busy = true;
        const query: any = {
            skip: params.startRow,
            top: params.endRow - params.startRow,
            orderBy: this.route.snapshot.queryParams['orderBy']
        };

        if (this.productionGroup1 != null && this.productionGroup1 !== 'all') {
            query['productionGroupCode1.value'] = this.productionGroup1;
            query['productionGroupCode1.operator'] = 'eq';
        }
        if (this.productionGroup2 != null) {
            query['productionGroupCode2.value'] = this.productionGroup2;
            query['productionGroupCode2.operator'] = 'eq';
        }

        const attrs = ['brandCode', 'eCommProductId', 'shortName', 'dimensionB', 'dimensionID', 'dimensionOD', 'dateCreated'];
        attrs.forEach(attr => {
            if (this[`${attr}.operator`] != null) { 
                query[`${attr}.operator`] = this[`${attr}.operator`]; 
            }
            if (this[`${attr}.value`] != null) {
                query[`${attr}.value`] = this[`${attr}.value`]; 
            }    
        });

        this.catalogueService.getProducts(query, this.catalogueMode)
            .subscribe(data => {
                this.totalRows = data.total;
                params.successCallback(data.rows, data.total);
                this.busy = false;
            }, err => {
                console.log(err);
                this.busy = false;
            });
    }
    getGridOptions() {
        return this.gridService.getProductGridOptions(parseInt(
            this.route.snapshot.queryParams['page'], 10), this.catalogueMode);
    }
    getColumns() {
        return this.gridService.getColumns(this.catalogueMode);
    }
    getColumnsDefs() {
        return this.gridService.getColumnDefs(this.catalogueMode);
    }

    scrollToTop() {
        setTimeout(() => {
            this.pageScrollService.scroll({ document: this.document, scrollTarget: '#detail' });
        }, 0);
    }

    getCategory(id: string): BrandCategory {
        let category = new BrandCategory('all', this.translate.instant('CATALOGUE_ALL'),
            '/' + this.catalogueService.getRoute(this.catalogueMode), { group1: 'all' });

        if (id === 'all') {
            return category;
        }
        this.brandCategories.forEach(item => {
            if (item.id === this.productionGroup1) {
                category = item;
            }
        });
        return category;
    }

    getSubCategory(id: string): BrandCategory {
        let subcategory = new BrandCategory('all', this.translate.instant('CATALOGUE_ALL'),
            '/' + this.catalogueService.getRoute(this.catalogueMode), { group1: 'all' })

        this.brandCategories.forEach(category => {
            category.children.forEach(item => {
                if (item.id === this.productionGroup2) {
                    subcategory = item;
                }
            });
        });
        return subcategory;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
