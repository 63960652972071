import { UserPreferences } from './user.preferences.model';
import { ProjectPriceList } from './customer.model';

export interface NotificationRole {
    id: number; // 91
    name: string; // 'orderCreation'
}

export enum Representative {
    'customer' = 'customer',
    'supplier' = 'supplier',
    'AS' = 'AS',
    'readAllRows' = 'readAllRows'
}

export enum RepreOverrideAreas {
    rfq = 'rfq',
    userAdmin = 'userAdmin',
 }

export type AvailableUserCustomer = { 
    id: number, 
    name: string, 
    checked: boolean, 
    priceListCode: string, 
    priceListCurrency: string 
};

export class User {
    preferences: UserPreferences; // do not use this for sensitive information, because it is used to remember e.g. selection after relogin
    representsCustomer: {
        id: string,
        name: string,
        customerStockActive: boolean,
        customerStockForOtherCustomers: boolean,
        customerStockForSubcustomers: boolean,
        priceListCode: string,
        priceListCurrency: string,
        projectPriceLists?: ProjectPriceList[]
    };
    representsSupplier: {id: string, name: string};
    representsAuthorizedSeller: {id: string, name: string};
    availableCustomers: AvailableUserCustomer[];
    availableSuppliers: [{ id: number, name: string }];
    availableAuthorizedSellers: [{id: string, name: string, checked: boolean}];
    readAllRows:                boolean;
    representative: Representative;
    enabled:                    boolean;
    repreOverride?: RepreOverride;

    constructor(
        public id: 			number,
        public firstName: 	string,
        public lastName: 	string,
        public phoneNumber: string,
        public email:    	string,
        public groups:      string[],
        public notificationRoles: NotificationRole[],
        public language:    string,
        public businessCondConsent: boolean,
        public homepage?: string,
        public subcustomer?: string,
        public isSubcustomer?: boolean,
        public isEditing?: boolean
    ) {
        // this.preferences = new UserPreferences();
        this.preferences = {};
    }
}

export interface UserGroup {
    id: string;
}

export interface NotificationRoleWithRules extends NotificationRole {
    rules: string[];
}

export interface AvailableUserOptions {
    groups: UserGroup[];
    notificationRoles: NotificationRoleWithRules[];
}

export interface RepreOverrideRequest {
    customerId: number;
}

export interface RepreOverride {
    customerId: number;
    customerAsId: number;
    area: RepreOverrideAreas;
    customerName: string;
}
