import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../shared.service';
import { TableService } from '../table.service';
import { BasketService } from './basket.service';
import { GridHeaderRendererComponent, GridHeaderRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridHeaderRendererComponent/grid.header.renderer.component';
import { GridHeaderSelectAllRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridHeaderSelectAllRendererComponent/grid.header.select.all.renderer.component';
import { GridLinkRendererComponent, GridLinkRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridLinkRendererComponent/grid.link.renderer.component';
import { GridNumberRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridNumberRendererComponent/grid.number.renderer.component';
import { GridPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPriceRendererComponent/grid.price.renderer.component';
import { GridRowPriceRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridRowPriceRendererComponent/grid.row.price.renderer.component';
import { GridRowWeightRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridRowWeightRendererComponent/grid.row.weight.renderer.component';
import { GridDateRendererComponent, GridDateRendererComponentParams } from '@components/tableComponent/gridRendererComponents/gridDateRendererComponent/grid.date.renderer.component';
import { GridDateEditorComponent } from '../../components/tableComponent/gridEditorComponents/gridDateEditorComponent/grid.date.editor.component';
import { GridSelectRendererComponent, GridSelectRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridSelectRendererComponent/grid.select.renderer.component';
import { GridSelectedRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridSelectedRendererComponent/grid.selected.renderer.component';
import { GridSelectEditorComponent } from '../../components/tableComponent/gridEditorComponents/gridSelectEditorComponent/grid.select.editor.component';
import { GridInputRendererComponent, GridInputRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridInputRendererComponent/grid.input.renderer.component';
import { GridInputEditorComponent } from '../../components/tableComponent/gridEditorComponents/gridInputEditorComponent/grid.input.editor.component';
import { GridAvailabilityRendererComponent, GridAvailabilityRendererComponentParams } from '../../components/tableComponent/gridRendererComponents/gridAvailabilityRendererComponent/grid.availability.renderer.component';
import { GridEditableRowActionsRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridEditableRowActionsRendererComponent/grid.editable.row.actions.renderer.component'
import { GridPlusRendererComponent } from '../../components/tableComponent/gridRendererComponents/gridPlusRendererComponent/grid.plus.renderer.component';
import { LanguageService } from '../../services/language.service';
// import { LocalNumberPipe } from 'app/locale.pipes.module';
import { CatalogueService, CatalogueModes } from '@services/catalogue/catalogue.service';
import { map } from 'rxjs/operators';
import { FormService } from '@services/form.service';
import { CellChange } from '@app/model/table.model';
import { ColDef, GridOptions } from 'ag-grid-community';
import { BasketItem } from '@app/model/basket.model';
import { GridHeaderActions2RendererComponent, GridHeaderActions2RendererComponentParams } from '@app/components/tableComponent/gridRendererComponents/gridHeaderActions2RendererComponent/grid.header.actions2.renderer.component';
import { Product } from '@app/model/product.model';
import { Packing } from '@app/model/packing.model';

@Injectable()
export class CurrentOrderGridService {
    subjectAllProductsOfCurrentOrderSelected: Subject<any>;
    subjectProductOfCurrentOrderSelected: Subject<any>;
    subjectProductOfCurrentOrderUpdated: Subject<any>;
    productsOfCurrentOrderEditedIds: any = {};

    cancelRowsObservable: Subject<any>;
    // subjectItemsUpdated: Subject<any>;
    cellChanged$: Subject<CellChange>;

    decimalDelimiter: string;

    public readonly packingsIdsAndNames: { id: Packing, name: string }[];

    constructor (
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private sharedService: SharedService,
        private tableService: TableService,
        private basketService: BasketService,
        private languageService: LanguageService,
        private catalogueService: CatalogueService,
        private formService: FormService
    ) {
        this.subjectAllProductsOfCurrentOrderSelected = new Subject<any>();
        this.subjectProductOfCurrentOrderSelected = new Subject<any>();
        this.subjectProductOfCurrentOrderUpdated = new Subject<any>();
        // this.subjectItemsUpdated = new Subject<any>();
        this.cancelRowsObservable = new Subject<any>();
        this.cellChanged$ = new Subject<CellChange>();

        const locale = this.languageService.getLocale();
        this.decimalDelimiter = locale[this.sharedService.appSettings.language].decimalDelimiter; // ',';
        this.packingsIdsAndNames = this.sharedService.params.packings.map((packingCode) => ({ id: packingCode, name: `PACKING_${packingCode}` }));
    }

    /**
     * Returns observable of all selection - used to detext change in header renderer of grid
     */
    getAllProductsOfCurrentOrderSelectedSubject(): Subject<any> {
        return this.subjectAllProductsOfCurrentOrderSelected;
    }

    /**
     * Returns observable of single selection - used to detect change in row selection to count weight andprice of selected items
     */
    getProductOfCurrentOrderSelectedSubject(): Subject<any> {
        return this.subjectProductOfCurrentOrderSelected;
    }

    getProductOfCurrentOrderUpdatedSubject(): Subject<any> {
        return this.subjectProductOfCurrentOrderUpdated;
    }

    getProductColumns(forceOriginal?: boolean) {
        const TO = this.translateService.instant('TO');
        const DAYS = this.translateService.instant('TO_DAYS');
        const OVER = this.translateService.instant('OVER');
        let original = [
            {id: 'brandCode', name: 'BRAND', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 60 },
            {id: 'productName', name: 'PRODUCT_ID', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'amountInPacking', name: 'AMOUNT_MOQ', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'customerStockAmount', name: 'CUSTOMER_STOCK', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            {id: 'amount', name: 'STOCK', checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'amountUponVerification', name: 'AMOUNT_UPON_VERIFICATION', checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'amountPlAs2', name: 'STOCK_PL', checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'amountSrAs2', name: 'STOCK_SR', checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'amountSkAs4', name: 'STOCK_SR', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            {id: 'amountCzAs4', name: 'STOCK_CZ', checked: true, orderBy: false, orderDirection: 'ASC', width: 70 },
            {id: 'futureDeliveryPeriod1', name: 'IN_PRODUCTION_PERIOD_1', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDeliveryPeriod2', name: 'IN_PRODUCTION_PERIOD_2', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDeliveryPeriod3', name: 'IN_PRODUCTION_PERIOD_3', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDeliveryPeriod4', name: 'IN_PRODUCTION_PERIOD_4', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDeliveryPeriod5', name: this.translateService.instant(this.translateService.instant('TO') + ' 90 ' + this.translateService.instant('TO_DAYS')), checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDeliveryPeriod6', name: this.translateService.instant(this.translateService.instant('TO') + ' 120 ' + this.translateService.instant('TO_DAYS')), checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDeliveryPeriod7', name: this.translateService.instant(this.translateService.instant('OVER') + ' 120 ' + this.translateService.instant('TO_DAYS')), checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDeliveryAs2', name: 'IN_PRODUCTION', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDelivery0_30As5', name: `${TO} 30 ${DAYS}`, checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDelivery30_60As5', name: `${TO} 60 ${DAYS}`, checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDelivery60_90As5', name: `${TO} 90 ${DAYS}`, checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDelivery90_120As5', name: `${TO} 120 ${DAYS}`, checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'futureDelivery120_As5', name: `${OVER} 120 ${DAYS}`, checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'dimensionID', name: 'd', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'dimensionOD', name: 'D', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'dimensionB', name: 'B', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'rdMin', name: 'RD_MIN', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'rdMax', name: 'RD_MAX', checked: true, orderBy: false, orderDirection: 'ASC', width: 50},
            {id: 'weight', name: 'WEIGHT', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'amountOrder', name: 'UNIT', doNotShowInTableSettings: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 60 },
            {id: 'priceTotal', name: 'PRICE', doNotShowInTableSettings: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 80 },
            {id: 'add', name: '', doNotShowInTableSettings: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 30}
        ]
        .filter(item => !this.catalogueService.isColumnHidden(item.id, CatalogueModes.Catalogue));

        original = this.tableService.filterSuppressColumns(original, 'basketCatalogue');
        const restored = this.sharedService.user.preferences['current-orderProductsTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getColumn(colId) {
        const columns = this.getProductColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }

    getProductGridOptions(startPage: number) {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        const gridOptions: any = this.tableService.getDefaultGridOptions(startPage, 'current-orderProductsTablePageSize', headerClassFunc);
        const headerComponentParams: GridHeaderRendererComponentParams = {
            columnsObjects: this.getProductColumns(),
            columnsPreferenceKey: 'current-orderProductsTableColumns',
            sharedService: this.sharedService,
            suppressToUrl: true
        };
        gridOptions.defaultColDef = {
            headerComponent : GridHeaderRendererComponent,
            headerComponentParams: headerComponentParams,
            headerClass: headerClassFunc,

        };
        gridOptions.headerHeight = 25;  // one line
        gridOptions.columnDefs = this.getProductsColumnDefs();
        return gridOptions;
    }

    getProductsColumnDefs() {
        function headerClassFunc(params) {
            return 'bkg-primary fnt-white';
        }

        function yellowHeaderClassFunc(params) {
            return 'bkg-secondary fnt-primary';
        }

        let colDefs: any = [
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('BRAND'),
                        field: 'brandCode',
                        width: this.getColumn('brandCode').width,
                        minWidth: 70,
                    }
                ]
            }
        ];

        // productName
        const productNameChild: ColDef = {
            headerName: this.translateService.instant('PRODUCT_ID'),
            field: 'productName',
            tooltipField: 'tooltip', // in a case of the product name change
            width: this.getColumn('productName').width,
            minWidth: 70,
            sort: 'asc',
            cellRendererFramework: GridLinkRendererComponent,
        };
        const productNameChildRendererParams: GridLinkRendererComponentParams = {
            iconClassAttr: 'iconClass'
        };
        productNameChild.cellRendererParams = productNameChildRendererParams;
        const productName = {
            headerName: '',
            headerClass: headerClassFunc,
            children: [ productNameChild ]
        };
        colDefs.push(productName);

        colDefs = colDefs.concat([
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('UNIT_PRICE'),
                        field: 'unitPrice',
                        width: this.getColumn('unitPrice').width, minWidth: 80,
                        cellRendererFramework: GridPriceRendererComponent,
                        cellRendererParams: { currency: this.sharedService.getUserCustomerCurrency() },
                        suppressSizeToFit: true
                    },
                ]
            },
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('AMOUNT_MOQ'),
                        field: 'amountInPacking',
                        width: 80, minWidth: 50,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                        suppressSizeToFit: true
                    },
                ]
            },
            {
                headerName: '',
                headerClass: headerClassFunc,
                children: [
                    ...(this.catalogueService.isColumnHidden('customerStockAmount', CatalogueModes.Catalogue) ? [] : [
                        {
                            headerName: this.translateService.instant('CUSTOMER_STOCK'),
                            field: 'customerStockAmount',
                            width: this.getColumn('customerStockAmount').width,
                            minWidth: 70,
                            cellRendererFramework: GridNumberRendererComponent,
                            cellRendererParams: { addDecimalZero: false, hideZeroValue: true }
                        },
                    ]),
                    {
                        headerName: this.translateService.instant('STOCK'),
                        field: 'amount',
                        width: this.getColumn('amount').width,
                        minWidth: 70,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    ...(this.catalogueService.isColumnHidden('amountUponVerification', CatalogueModes.Catalogue) ? [] : [
                        {
                            headerName: this.translateService.instant('AMOUNT_UPON_VERIFICATION'),
                            field: 'amountUponVerification',
                            width: this.getColumn('amountUponVerification').width,
                            minWidth: 70,
                            cellRenderer: (params) => {
                                const product: Product = params.data;
                                if (product.amountUponVerification > 0) {
                                    return `
                                        <i class="fa fa-phone" aria-hidden="true" title="${this.translateService.instant('UPON_VERIFICATION_INFO')}"></i>
                                    `;
                                } else {
                                    return '';
                                }
                            }
                        },
                    ]),
                    {
                        headerName: this.translateService.instant('STOCK_PL'),
                        field: 'amountPlAs2',
                        width: this.getColumn('amountPlAs2').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true},
                    },
                    {
                        headerName: this.translateService.instant('STOCK_SR'),
                        field: 'amountSrAs2',
                        width: this.getColumn('amountSrAs2').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true},
                    },
                    {
                        headerName: this.translateService.instant('STOCK_SR'),
                        field: 'amountSkAs4',
                        width: this.getColumn('amountSkAs4').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                    },
                    {
                        headerName: this.translateService.instant('STOCK_CZ'),
                        field: 'amountCzAs4',
                        width: this.getColumn('amountCzAs4').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true },
                    }
                ]
            },
            {
                headerName: this.translateService.instant('IN_PRODUCTION'),
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION_PERIOD_1'),
                        field: 'futureDeliveryPeriod1',
                        width: this.getColumn('futureDeliveryPeriod1').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION_PERIOD_2'),
                        field: 'futureDeliveryPeriod2',
                        width: this.getColumn('futureDeliveryPeriod2').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION_PERIOD_3'),
                        field: 'futureDeliveryPeriod3',
                        width: this.getColumn('futureDeliveryPeriod3').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION_PERIOD_4'),
                        field: 'futureDeliveryPeriod4',
                        width: this.getColumn('futureDeliveryPeriod4').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    {
                        headerName: this.translateService.instant('IN_PRODUCTION'),
                        field: 'futureDeliveryAs2',
                        width: this.getColumn('futureDeliveryAs2').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    {
                        headerName: this.getColumn('futureDelivery0_30As5').name,
                        field: 'futureDelivery0_30As5',
                        width: this.getColumn('futureDelivery0_30As5').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true }
                    },
                ]
            },
            {
                headerName: this.translateService.instant('IN_PRODUCTION_PLAN'),
                headerClass: headerClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant(this.translateService.instant('TO') + ' 90 ' + this.translateService.instant('TO_DAYS')),
                        field: 'futureDeliveryPeriod5',
                        width: this.getColumn('futureDeliveryPeriod5').width,
                        minWidth: 70,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    {
                        headerName: this.translateService.instant(this.translateService.instant('TO') + ' 120 ' + this.translateService.instant('TO_DAYS')),
                        field: 'futureDeliveryPeriod6',
                        width: this.getColumn('futureDeliveryPeriod5').width,
                        minWidth: 70,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    {
                        headerName: this.translateService.instant(this.translateService.instant('OVER') + ' 120 ' + this.translateService.instant('TO_DAYS')),
                        field: 'futureDeliveryPeriod7',
                        width: this.getColumn('futureDeliveryPeriod5').width,
                        minWidth: 70,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: {addDecimalZero: false, hideZeroValue: true}
                    },
                    {
                        headerName: this.getColumn('futureDelivery30_60As5').name,
                        field: 'futureDelivery30_60As5',
                        width: this.getColumn('futureDelivery30_60As5').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true }
                    },
                    {
                        headerName: this.getColumn('futureDelivery60_90As5').name,
                        field: 'futureDelivery60_90As5',
                        width: this.getColumn('futureDelivery60_90As5').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true }
                    },
                    {
                        headerName: this.getColumn('futureDelivery90_120As5').name,
                        field: 'futureDelivery90_120As5',
                        width: this.getColumn('futureDelivery90_120As5').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true }
                    },
                    {
                        headerName: this.getColumn('futureDelivery120_As5').name,
                        field: 'futureDelivery120_As5',
                        width: this.getColumn('futureDelivery120_As5').width,
                        minWidth: 50,
                        suppressSizeToFit: true,
                        cellRendererFramework: GridNumberRendererComponent,
                        cellRendererParams: { addDecimalZero: false, hideZeroValue: true }
                    },
                ]
            },
            {
                headerName: this.translateService.instant('DIMENSIONS'),
                headerClass: headerClassFunc,
                children: [
                    {headerName: 'd', field: 'dimensionID', width: this.getColumn('dimensionID').width, minWidth: 30, cellRendererFramework: GridNumberRendererComponent, suppressSizeToFit: true},
                    {headerName: 'D', field: 'dimensionOD', width: this.getColumn('dimensionOD').width, minWidth: 30, cellRendererFramework: GridNumberRendererComponent, suppressSizeToFit: true},
                    {headerName: 'B', field: 'dimensionB', width: this.getColumn('dimensionB').width, minWidth: 30, cellRendererFramework: GridNumberRendererComponent, suppressSizeToFit: true},
                    {
                        headerName: this.translateService.instant('RD_MIN'), 
                        field: 'rdMin', 
                        width: this.getColumn('rdMin').width, 
                        minWidth: 30, 
                        cellRendererFramework: GridNumberRendererComponent, 
                        suppressSizeToFit: true
                    },
                    {
                        headerName: this.translateService.instant('RD_MAX'), 
                        field: 'rdMax', 
                        width: this.getColumn('rdMax').width, 
                        minWidth: 30, 
                        cellRendererFramework: GridNumberRendererComponent, 
                        suppressSizeToFit: true
                    }
                ]
            },
            {
                headerName: this.translateService.instant('WEIGHT'),
                headerClass: headerClassFunc,
                children: [
                    {headerName: 'kg', field: 'weight', width: this.getColumn('weight').width, minWidth: 80,
                    cellRendererFramework: GridNumberRendererComponent, cellRendererParams: {addDecimalZero: true, decimals: 3},
                    cellStyle: {'text-align': 'right'}, suppressSizeToFit: false}
            ]},
            {
                headerName: this.translateService.instant('TO_ORDER'),
                headerClass: yellowHeaderClassFunc,
                children: [
                    {
                        headerName: this.translateService.instant('UNIT'), field: 'amountOrder', width: 60, minWidth: 60, suppressSizeToFit: true,
                        sortable: false,
                        headerClass: yellowHeaderClassFunc,
                        cellRendererFramework: GridInputRendererComponent,
                        cellRendererParams: {
                            number: true,
                            maxWidth: '56px'
                        },
                        cellEditorFramework: GridInputEditorComponent,
                        cellEditorParams:  {
                            onEnter: data => {
                                this.basketService.addToOrder([
                                    { amountOrdered: data.amountOrder,
                                    eCommProductId: data.eCommProductId,
                                    brandCode: data.brandCode,
                                    deliveryDate: ''
                                    }]).subscribe(result => {
                                        data.amountOrder = null;
                                        data.priceTotal = 0.00;
                                        this.basketService.getReloadCurrentSubject().next();
                                    }, err => console.log(err));
                            },
                            inputType: 'number',
                            maxWidth: '56px'
                        },
                        editable: true
                    },
                    {
                        headerName: '', field: 'add', width: 30, headerClass: yellowHeaderClassFunc, suppressSizeToFit: true,
                        sortable: false,
                        cellRendererFramework: GridPlusRendererComponent,
                    },
                    ...(this.catalogueService.isColumnHidden('priceTotal', CatalogueModes.Catalogue) ? [] : [
                        {
                            headerName: this.translateService.instant('PRICE').toLowerCase(), field: 'priceTotal',
                            width: 80,
                            headerClass: yellowHeaderClassFunc,
                            suppressSizeToFit: true,
                            sortable: false,
                            cellRendererFramework: GridRowPriceRendererComponent,
                            cellRendererParams   : { amountColId: 'amountOrder', currency: this.sharedService.getUserCustomerCurrency() }
                        }
                    ])
                ]
            },
            // {
            //     headerName: this.translateService.instant('CHECK'),
            //     headerClass: yellowHeaderClassFunc,
            //     children: [
            //         {
            //             headerName: this.translateService.instant('AVAILABILITY'), field: 'available', width: 70, suppressSizeToFit: true,
            //             sortable: false,
            //             headerClass: yellowHeaderClassFunc,
            //             cellRendererFramework: GridInputRendererComponent,
            //         },
            //         {
            //             headerName: '', field: 'availability', width: 30, headerClass: yellowHeaderClassFunc, suppressSizeToFit: true,
            //             sortable: false, cellRendererFramework: GridPlusRendererComponent,
            //         }
            //     ]
            // }

        ]);

        colDefs = this.tableService.filterSuppressColumns(colDefs, 'basketCatalogue');
        return colDefs;
    }





    getProductsOfCurrentOrderColumns(forceOriginal?: boolean) {
        let original = [
            {id: 'selected', name: 'SELECTED', hidden: true, checked: false, orderBy: false, width: 30},
            {id: 'brandCode', name: 'BRAND', alwaysVisible: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 70},
            {id: 'eCommProductId', name: 'PRODUCT_ID', alwaysVisible: true, checked: true, orderBy: true, orderDirection: 'ASC', width: 110},
            {id: 'amountOrdered', name: 'PIECES', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'amountInPacking', name: 'AMOUNT_MOQ', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'amountPackages', name: 'AMOUNT_PACKAGES', checked: true, orderBy: false, orderDirection: 'ASC', width: 90},
            {id: 'availabilityState', name: 'AVAILABILITY', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'deliveryDate', name: 'DATE_OF_DELIVERY', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'packing', name: 'PACKING_FORM', checked: true, orderBy: false, orderDirection: 'ASC', width: 110},
            {id: 'rowWeight', name: 'WEIGHT_KG', checked: true, orderBy: false, orderDirection: 'ASC', width: 75},
            {id: 'unitPrice', name: 'UNIT_PRICE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'rowPrice', name: 'TOTAL_VALUE', checked: true, orderBy: false, orderDirection: 'ASC', width: 80},
            {id: 'actions', name: 'ACTIONS', hidden: true, checked: true, orderBy: false, orderDirection: 'ASC', width: 170},
        ]
        .filter(item => !this.isColumnHidden(item.id));

        original = this.tableService.filterSuppressColumns(original, 'basketItems');
        const restored = this.sharedService.user.preferences['current-orderProductsOfOrderTableColumns'];
        if (restored && !forceOriginal) {
            if (!this.sharedService.hasImplementationOfCOlumnsChanged(original, restored)) {
                return restored;
            } else {
                return original;
            }
        } else {
            return original;
        }
    }

    getProductsOfCurrentOrderColumn(colId) {
        const columns = this.getProductsOfCurrentOrderColumns();
        for (let i = 0; i < columns.length; ++i) {
            if (columns[i].id === colId) {
                return columns[i];
            }
        }
        return {name: '_', width: 0};
    }
    headerClassFunc(params) {
        return 'bkg-gray-darker';
    }
    public getProductsOfCurrentOrderProductGridOptions(startPage: number): GridOptions {
        const this_ = this;

        function headerClassFunc(params) {
            return 'bkg-gray-darker';
        }
        function yellowHeaderClassFunc(params) {
            return 'bkg-secondary fnt-primary';
        }

        const gridOptions = this.tableService.getDefaultGridOptions(startPage, 'current-orderProductsOfOrderTablePageSize', headerClassFunc);
        // gridOptions.defaultColDef = null;
        gridOptions.singleClickEdit = false;
        gridOptions.columnDefs = this_.getProductsOfCurrentOrderColumnDefs();

        gridOptions.rowClassRules = {
            'table-row-disabled': params => this.isRowDisabled(params)
        };
        return gridOptions;
    }

    private isRowDisabled(params: {data: BasketItem}): boolean {
        // Disable row if it has been batch edited and saved while there are some not saved rows
        return !!params.data._disabled;
    }

    getProductsOfCurrentOrderColumnDefs() {
        const this_ = this;
        let colDefs: ColDef[] = [
            {
                headerName: '',
                field: 'selected',
                pinned: 'left',
                width: 30, minWidth: 30, maxWidth: 30,
                headerClass: this.headerClassFunc,
                cellRendererFramework: GridSelectedRendererComponent,
                cellRendererParams: {
                    selection: this_.sharedService.user.preferences.productsOfCurrentOrderSelection,
                    observable: this_.getProductOfCurrentOrderSelectedSubject(),
                    editedIdsObj: this_.productsOfCurrentOrderEditedIds
                },
                headerComponent : GridHeaderSelectAllRendererComponent,
                headerComponentParams : {
                    selection: this_.sharedService.user.preferences.productsOfCurrentOrderSelection,
                    editedIdsObj: this_.productsOfCurrentOrderEditedIds,
                    observable: this_.getAllProductsOfCurrentOrderSelectedSubject()
                },
                sortable: false,
            },
            {
                headerName: this_.translateService.instant('AVAILABILITY'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'availabilityState',
                width: this_.getProductsOfCurrentOrderColumn('availabilityState').width,
                cellRendererFramework: GridAvailabilityRendererComponent,
                cellRendererParams: {
                    cellChanged$: this.cellChanged$
                } as GridAvailabilityRendererComponentParams
            },
            {
                headerName: this_.translateService.instant(this_.getProductsOfCurrentOrderColumn('brandCode').name),
                // headerComponent: GridHeaderRendererComponent,
                // headerComponentParams: {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'brandCode',
                minWidth: 70,
                width: this_.getProductsOfCurrentOrderColumn('brandCode').width,
            }
        ];

        // eCommProductId
        const eCommProductId: ColDef = {
            headerName: this_.translateService.instant('PRODUCT_ID'),
            field: 'eCommProductId',
            width: this_.getProductsOfCurrentOrderColumn('eCommProductId').width,
            minWidth: 110,
            cellStyle: {'text-align': 'left'},
            cellClass: (params) => {
                return params.data.nonCancelable === 1 ? 'fnt-blue' : '';
            },
            cellRendererFramework: GridLinkRendererComponent,
            valueGetter: (params) => params.data.eCommProductId + (params.data.nonCancelable === 1 ? ' *' : ''),
            tooltipValueGetter: (params) => {
                const basketItem: BasketItem = params.data;

                // Product name change tooltip
                let tooltip = this.catalogueService.getProductTooltip(
                    basketItem.eCommProductIdOld, basketItem.eCommProductIdNew);

                // Non cancelable product tooltip
                if (basketItem.nonCancelable === 1) {
                    if (tooltip.length > 0) {
                        tooltip += '. ';
                    }
                    tooltip += this_.translateService.instant('NON_CANCELABLE_INFO');
                }

                return tooltip;
            }
        };
        const eCommProductIdRendererParams: GridLinkRendererComponentParams = {
            iconClassAttr: 'productIconClass'
        };
        eCommProductId.cellRendererParams = eCommProductIdRendererParams;
        colDefs.push(eCommProductId);

        colDefs = colDefs.concat([
            {
                headerName: this_.translateService.instant('PIECES'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'amountOrdered',
                width: this_.getProductsOfCurrentOrderColumn('amountOrdered').width,
                minWidth: this_.getProductsOfCurrentOrderColumn('amountOrdered').width,
                maxWidth: 110,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: false},
                cellStyle: {'text-align': 'right'},
            },
            {
                headerName: this_.translateService.instant('AMOUNT_MOQ'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'amountInPacking',
                width: this_.getProductsOfCurrentOrderColumn('amountInPacking').width,
                minWidth: this_.getProductsOfCurrentOrderColumn('amountInPacking').width,
                maxWidth: 110,
                suppressSizeToFit: true,
                cellRendererFramework: GridNumberRendererComponent,
                cellRendererParams: {addDecimalZero: false},
                cellStyle: {'text-align': 'right'},
            },
            {
                headerName: this_.translateService.instant('AMOUNT_PACKAGES'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'amountPackages',
                width: this_.getProductsOfCurrentOrderColumn('amountPackages').width,
                minWidth: this_.getProductsOfCurrentOrderColumn('amountPackages').width,
                maxWidth: 110,
                suppressSizeToFit: true,
                cellRendererFramework: GridInputRendererComponent,
                cellRendererParams: {
                    editedIdsObj: this_.productsOfCurrentOrderEditedIds,
                    number: true,
                    decimal: false,
                    isColValid: (colId, value) => {
                        // return value && value > 0 && value < 1000000 && (value % 1 === 0) && value.replace(',', '.').indexOf('.') < 0;
                        return value && value > 0 && value < 1000000 && (value % 1 === 0) && value.indexOf(this_.decimalDelimiter) < 0;
                    },
                    invalidPopoverText: 'INVALID_PACKING_AMOUNT_INFO',
                    allowMultipleEdit: true,
                    cellChanged$: this.cellChanged$
                } as GridInputRendererComponentParams,
                cellEditorFramework: GridInputEditorComponent,
                cellEditorParams:  {
                    editedIdsObj: this_.productsOfCurrentOrderEditedIds,
                    inputType: 'number',
                    isColValid: (colId, value) => {
                        return value && value > 0 && value < 1000000 && (value % 1 === 0) && value.replace(',', '.').indexOf('.') < 0;
                    },
                    invalidPopoverText: 'INVALID_PACKING_AMOUNT_INFO',
                    onChange: (params) => {
                        if (params.node.data.originalValues.filter(origVal => origVal.colId === 'amountPackagesNotRounded').length === 0) {
                            params.node.data.originalValues.push({colId: 'amountPackagesNotRounded', value: params.node.data.amountPackagesNotRounded});
                        }
                        params.node.data.amountPackagesNotRounded = parseFloat(params.node.data.amountPackages.toString().replace(' ', '').replace(',', '.'));
                        params.node.data.amountOrdered = Math.round(params.node.data.amountInPacking * params.node.data.amountPackagesNotRounded);
                        params.node.data.rowPrice = this.getCoefficientRowPrice(params.node);
                        params.api.refreshCells({rowNodes: [params.node], columns: ['amountOrdered', 'rowPrice']});
                    },
                    allowMultipleEdit: true,
                    cellChanged$: this.cellChanged$
                },
                editable: true,
            },
            {
                headerName: this_.translateService.instant('DATE_OF_DELIVERY'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'deliveryDate',
                width: this_.getProductsOfCurrentOrderColumn('deliveryDate').width,
                minWidth: 110,
                suppressSizeToFit: true,
                cellRendererFramework: GridDateRendererComponent,
                cellRendererParams: {
                    editable: true,
                    editedIdsObj: this_.productsOfCurrentOrderEditedIds,
                    validatorFn: this.basketService.isItemDateValid,
                    validatorMessageFn: this.basketService.getInvalidItemDateMessage,
                    allowMultipleEdit: true,
                    cellChanged$: this.cellChanged$
                } as GridDateRendererComponentParams,
                cellEditorFramework: GridDateEditorComponent,
                cellEditorParams:  {
                    editedIdsObj: this_.productsOfCurrentOrderEditedIds,
                    minDateAttrName: 'minDeliveryDate',
                    allowNullDate: true,
                    allowMultipleEdit: true,
                    cellChanged$: this.cellChanged$
                },
                editable: true,
                cellClass: 'overflow-visible'
            },
            {
                headerName: this_.translateService.instant('PACKING_FORM'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'packing',
                width: this_.getProductsOfCurrentOrderColumn('packing').width,
                minWidth: 110,
                maxWidth: 200,
                cellRendererFramework: GridSelectRendererComponent,
                cellRendererParams: {
                    itemsGetter: (params) => this.formService.getPackingOptionsForRow(params.data),
                    editedIdsObj: this_.productsOfCurrentOrderEditedIds,
                    allowMultipleEdit: true,
                    onChange: (params) => {
                        params.data.amountInPacking = params.data.packingOptions.filter(item => item.packing === params.data.packing)[0].amount;
                        params.data.amountPackages = (Math.round((params.data.amountOrdered / params.data.amountInPacking) * 100) / 100);
                        params.data.unitPrice = this.getCoefficientUnitPrice(params);
                        params.data.rowPrice = this.getCoefficientRowPrice(params);
                        params.api.refreshCells({rowNodes: [params.node], columns: ['amountPackages', 'amountInPacking', 'unitPrice']});
                    },
                    cellChanged$: this.cellChanged$
                } as GridSelectRendererComponentParams,
                cellEditorFramework: GridSelectEditorComponent,
                cellEditorParams:  {
                    itemsGetter: (params) => this.formService.getPackingOptionsForRow(params.data),
                    cellChanged$: this.cellChanged$
                },
                editable: (params) => params.data.packingOptions.length > 1,
                cellClass: 'overflow-visible'
            },
            {
                headerName: this_.translateService.instant('WEIGHT_KG'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'rowWeight',
                width: this_.getProductsOfCurrentOrderColumn('rowWeight').width,
                minWidth: 80,
                cellRendererFramework: GridRowWeightRendererComponent,
                cellRendererParams: {amountColId: 'amountOrdered'},
            },
            {
                headerName: this_.translateService.instant('UNIT_PRICE'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'unitPrice',
                width: this_.getProductsOfCurrentOrderColumn('unitPrice').width,
                minWidth: 80,
                cellRendererFramework: GridPriceRendererComponent,
            },
            {
                headerName: this_.translateService.instant('TOTAL_VALUE'),
                // headerComponent : GridHeaderRendererComponent,
                // headerComponentParams : {
                //     router: this.router,
                //     activatedRoute: this.activatedRoute
                // },
                // headerClass: this.headerClassFunc,
                field: 'rowPrice',
                width: this_.getProductsOfCurrentOrderColumn('rowPrice').width,
                minWidth: 80,
                cellRendererFramework: GridRowPriceRendererComponent,
                cellRendererParams   : {
                    amountColId: 'amountOrdered',
                },
            }
        ]);

        // actions
        const actionsHeaderComponentParams: GridHeaderActions2RendererComponentParams = {
            isRowValid: row => this.isRowValid(row),
            editedIdsObj: this_.productsOfCurrentOrderEditedIds,
            cancel$: this.cancelRowsObservable,
            reload$: this.basketService.getReloadCurrentSubject(),
            save: (data: BasketItem[]) => {
                let result: { id: number, amountOrdered?: number, deliveryDate?: any, packing?: string}[];
                result = data.map(basketItem => {
                    const amountPackages = basketItem.originalValues.filter(x => x.colId === 'amountPackages')[0].value;
                    const deliveryDate = basketItem.originalValues.filter(x => x.colId === 'deliveryDate')[0].value;
                    const packing = basketItem.originalValues.filter(x => x.colId === 'packing')[0].value;

                    const packingAmount = basketItem.packingOptions.filter(x => x.packing === basketItem.packing)[0].amount;
                    return {
                        id: basketItem.id,
                        ...(amountPackages.toString() !== basketItem.amountPackages.toString() ? { amountOrdered: basketItem.amountPackages * packingAmount } : {}),
                        ...(deliveryDate !== basketItem.deliveryDate ? { deliveryDate: basketItem.deliveryDate } : {}),
                        ...(packing !== basketItem.packing ? { packing: basketItem.packing } : {}),
                    };
                })
                .filter(item => Object.keys(item).length > 1);

                return this.basketService.updateAttributesOfOrderedItems(result).pipe(
                    map(innerData => {
                        // this.basketService.getReloadCurrentSubject().next();
                        return innerData;
                    })
                );
            },
        };
        const actions: ColDef = {
            headerName: this_.translateService.instant('ACTIONS'),
            field: 'actions',
            width: this_.getProductsOfCurrentOrderColumn('actions').width,
            minWidth: this_.getProductsOfCurrentOrderColumn('actions').width,
            maxWidth: this_.getProductsOfCurrentOrderColumn('actions').width,
            headerComponent: null,
            headerClass: (params) => this.headerClassFunc(params) + ' active-on-edited-rows',
            headerComponentFramework: GridHeaderActions2RendererComponent,
            headerComponentParams: actionsHeaderComponentParams,
            cellRendererFramework: GridEditableRowActionsRendererComponent,
            cellRendererParams: {
                showCustomerStock: this.sharedService.params.features.customer.customerStock &&
                    !this.sharedService.hasPermission('preorders', 'POST'),
                selection: this_.sharedService.user.preferences.productsOfCurrentOrderSelection,
                editedIdsObj: this_.productsOfCurrentOrderEditedIds,
                observable: this_.getProductOfCurrentOrderUpdatedSubject(),
                cancelObservable: this.cancelRowsObservable,
                isRowValid: row => this.isRowValid(row),
            },
            sortable: false,
        };
        
        colDefs.push(actions);

        return this.tableService.filterSuppressColumns(colDefs, 'basketItems')
            .filter(colDef => !this.isColumnHidden(colDef.field));
    }

    /**
     * Validates the whole record (item row)
     */
    isRowValid(row: any): boolean {
        return Object.keys(row).reduce((valid: boolean, field) => valid && this.isColValid(field, row[field], row), true);
    }

    /**
     * Validates one column (item field)
     */
    isColValid(field: string, value: any, row: any): boolean {
        const validationMap = {
            amountPackages: val => {
                const valReplaced = val.toString().replace(' ', '');
                if (row.originalValues.filter(item => item.colId === 'amountPackages')[0].value.toString().replace(' ', '') !== valReplaced) {
                    return val && (val > 0 && val < 1000000 && (val % 1 === 0) && valReplaced.replace(',', '.').indexOf('.') < 0);
                }
                return val ? parseInt(valReplaced, 10) > 0 && parseInt(valReplaced, 10) < 1000000 : false;
            },
        };

        return (field in validationMap) ? validationMap[field](value) : true;
    }

    getCoefficientRowPrice(params) {
        return Math.round((params.data.amountOrdered * this.getCoefficientUnitPrice(params)) * 100) / 100;
    }

    getCoefficientUnitPrice(params) {
        const packingOption = params.data.packingOptions.filter(item => item.packing === params.data.packing)[0];
        if (packingOption && packingOption.priceCoefficient > 1) {
            const conuntedPrice = Math.round((((params.data.baseUnitPrice) * (packingOption.priceCoefficient)) * 10000)) / 10000;
            return conuntedPrice;
        }
        return params.data.baseUnitPrice;
    }

    // getItemsUpdatedSubject() {
    //     return this.subjectItemsUpdated;
    // }

    getProductsOfCurrentOrderFilterItems() {
        let filterItems = [
            // {id: 'deliveryDate', name: 'DATE_OF_DELIVERY', type: 'date', value: '', operator: {id: 'between', name: 'EQUAL', short: '='}},
            {id: 'deliveryDate', name: 'DATE_OF_DELIVERY', type: 'dateRange', valueFrom: '', valueTo: ''},
            {id: 'eCommProductId', name: 'PRODUCT_ID', type: 'text', maxLength: 255, value: '', operator: 'likeBoth'},
            {id: 'packing', name: 'PACKING_FORM', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES', value: '',
                values: this.packingsIdsAndNames
            },
            {id: 'availabilityState', name: 'AVAILABILITY', type: 'multiselect', allLabel: 'CATALOGUE_ALL', selectedLabel: 'SELECTED_STATES',
                value: '', values: [{id: 'COMPLETELY_FULFILLED', name: 'COMPLETELY_FULFILLED', dotId: 'P'}, {id: 'LATELY_FULFILLED', name: 'LATELY_FULFILLED', dotId: 'C'}, {id: 'PARTIALLY_FULFILLED', name: 'PARTIALLY_FULFILLED', dotId: 'N'}]},
            {id: 'valid', title: 'SHOW_INVALID_ONLY', type: 'checkbox', checkedValue: '0', value: '0', operator: 'eq', checked: false },
        ]
        filterItems = this.tableService.filterSuppressColumns(filterItems, 'basketItems');
        return filterItems;
    }

    /**
     * Get packing options returned from server, map with PACKING_ prefix
     */
    // getPackingOptionsForRow(row) {
    //     return row.packingOptions.map(item => {
    //         return {
    //             id: item.packing,
    //             name: this.translateService.instant('PACKING_' + item.packing) +
    //                 (item.priceCoefficient > 1 ? (' (+' +
    //                 new LocalNumberPipe(
    //                     this.languageService).transform((Math.round((item.priceCoefficient - 1) * 1000000) / 10000),
    //                     this.sharedService.appSettings.language
    //                 ) + '%)') : '') // add coefficient percentage to name of packing item
    //         };
    //     });
    // }

    private isColumnHidden(colName: string): boolean {
        const map = {
            'availabilityState': () => this.sharedService.hasPermission('preorders', 'POST'),
            'unitPrice': () => this.sharedService.hasPermission('preorders', 'POST'),
            'rowPrice': () => this.sharedService.hasPermission('preorders', 'POST')
        };
        return (colName in map) ? map[colName]() : false;
    }

}
